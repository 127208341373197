import BlokContainer from "@/components/blok-container";
import { LOADING_IMAGE_URL } from "@/utils/constant";
import { Center, Text, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import lozad from "lozad";

const AllInOne = () => {
  useEffect(() => {
    const { observe } = lozad(".lozad", {
      loaded: (el) => {
        try {
          el.onload = () => {
            el.classList.add("image-blur");
          };
        } catch (err) {}
      },
    });

    observe();
  }, []);
  return (
    <BlokContainer
      other={{
        paddingBottom: "0px",
      }}
      blockBg="linear-gradient(180deg, rgba(244, 247, 254, 0) 0%, #E3EDFF 100%);"
      blockTitle="Keep All Meeting Notes and Decisions in One Place"
      blockSubTitle={
        <Text
          style={{ margin: "0", marginTop: "24px" }}
          as="p"
          color="#7E7186"
          textAlign="center"
          maxW="1200px"
        >
          All meeting recordings and notes are kept in the workspace as a
          searchable knowledge base for the entire team, making onboarding new
          hire and sales coaching much easier.
        </Text>
      }
      maxW="web_max_w"
    >
      <Image
        className="lozad"
        objectFit="contain"
        src={LOADING_IMAGE_URL}
        alt="Airgram web app"
        data-src="https://a.storyblok.com/f/167495/2410x1023/7befcd965d/all-in-one-page.png"
        maxW="1200px"
        w="full"
        h="auto"
      />
    </BlokContainer>
  );
};

export default AllInOne;
