import * as React from "react";
import Layout from "@layout/index";

import { Link as ChakraLink, useBreakpointValue } from "@chakra-ui/react";

import Header from "@components/head";
import HeroBanner from "@/modules/home/components/hero-banner";
import TeamsLogo from "@/modules/home/components/teams-logo";
import ProblemsBox from "@/modules/home/components/problems-box";
import FeaturesScroll from "@/modules/home/components/features-scroll";
import AllInOne from "@/modules/home/components/all-in-one";
import IntegrateApp from "@/modules/home/components/integrate-app";
import AccomplishMore from "@/modules/home/components/accomplist-more";
import PrivacySupport from "@/modules/home/components/privacy-support";
import FeaturesSwiper from "@/modules/home/components/features-swiper";
import UserReview from "@/modules/home/components/user-review-swiper";
import BottomCta from "@/modules/home/components/bottom-cta";
import BlokContainer from "@/components/blok-container";
import AISummary from "@/modules/home/components/ai-summary";

const { memo } = React;

export const Head = () => {
  return (
    <Header
      cover="https://a.storyblok.com/f/167495/1800x946/92667d3cfa/open-graph-image-airgram.png"
      title="Airgram | AI Assistant for Automated Meeting Notes & Summary"
      description="Use Airgram to automatically record, transcribe, and summarize meetings to streamline workflow. Works perfectly with Zoom, Google Meet,  Teams & Webex."
    ></Header>
  );
};

export interface IHomeProps {}

export const Home: React.FC<IHomeProps> = (props) => {
  const {} = props;

  const isRowLayout = useBreakpointValue(
    {
      base: false,
      lg: true,
    },
    {
      fallback: "lg",
    }
  );

  return (
    <Layout
      isNewStyle
      px="0px"
      margin="0px"
      minW="100%"
      pt="0px"
      navMaxW="web_max_w"
    >
      <HeroBanner />
      <TeamsLogo />
      <AISummary />
      {/* <ProblemsBox /> */}
      {isRowLayout ? <FeaturesScroll /> : <FeaturesSwiper />}
      <AllInOne />
      <UserReview />
      <IntegrateApp />
      <AccomplishMore />
      <PrivacySupport />
      <BlokContainer
        blockBg="#F8F9FE"
        other={{
          paddingTop: "0px",
        }}
        maxW="web_max_w"
      >
        <BottomCta />
      </BlokContainer>
    </Layout>
  );
};
export default memo(Home);
