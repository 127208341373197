import { Box } from "@chakra-ui/react";
import React from "react";

export interface ISlideItemData {
    paginationContent: React.ReactNode,
    title: string,
    desc: string,
    link?: {
        linkUrl: string,
        linkLabel: string
    }
    imageUrl: string
}

export interface IUserReviewCard {
    username: string,
    userAvatar: string,
    userJob: string,
    reviewPlatformLogo: string,
    platformDesc: string,
    reviewTitle: string,
    reviewDesc: string
}

export interface IFeatureIntro {
    iconUrl: string,
    featureName: string,
    featureDesc: string,
    link?: {
        linkLabel: string,
        linkUrl: string,
        linkColor: string,
    }
}

export interface IStatisticalDataIntro {
    number: number,
    desc?: string,
    prefix?: string,
    suffix?: string,
    decimalPlaces: number
}

export interface IFeatureVideoIntro {
    featureWord: string,
    featureWordBgColor: string,
    featureWordFontColor: string,
    featureTitle: string,
    featureDesc: string[],
    link?: {
        linkLabel: string,
        linkColor: string,
        linkUrl: string,
    },
    videoUrl: string,
}

export interface IHonorIconIntro {
    alt: string,
    url: string,
    linkUrl?: string
}

export const PaginationWrapper = ({ children }) => {
    return <Box
        fontWeight="700"
        fontSize={{
            base: "20px",
            sm: "22px",
            md: "22px",
            lg: "24px",
            xl: "24px",
            "2xl": "24px"
        }}
    >
        {children}
    </Box>
}

export const SlideData: ISlideItemData[] = [
    {
        paginationContent: <PaginationWrapper>1</PaginationWrapper>,
        title: "Start recording",
        desc: "Log in to the web app. You may either choose a scheduled meeting on your calendar or launch a new meeting, then start recording.",
        imageUrl: "https://a.storyblok.com/f/167495/1968x1476/8b77a22c33/home-page-step-1.png",
        link: {
            linkLabel: 'Start Now',
            linkUrl: "https://app.airgram.io/signup"
        }
    },
    {
        paginationContent: <PaginationWrapper>2</PaginationWrapper>,
        title: "Admit AI assistant to join the meeting",
        desc: "Allow the Airgram AI assistant to join the meeting and it will automatically record and transcribe the conversations in real time.",
        imageUrl: "https://a.storyblok.com/f/167495/1968x1476/eb24b265bb/home-page-step-2.png",
        link: {
            linkLabel: 'Start Now',
            linkUrl: "https://app.airgram.io/signup"
        }
    },
    {
        paginationContent: <PaginationWrapper>3</PaginationWrapper>,
        title: "Get real-time transcription and take notes",
        desc: "Enjoy your discussion with other participants and Airgram will record everything. A notepad is integrated for you to collaborate on notes.",
        imageUrl: "https://a.storyblok.com/f/167495/1968x1476/db6b0cbf31/home-page-step-3.png",
        link: {
            linkLabel: 'Start Now',
            linkUrl: "https://app.airgram.io/signup"
        }
    },
    {
        paginationContent: <PaginationWrapper>4</PaginationWrapper>,
        title: "Review meeting recording and share",
        desc: "The meeting recording and transcript is immediately available for a review when you stop recording. Edit, clip, and share as you like.",
        imageUrl: "https://a.storyblok.com/f/167495/1968x1476/f1248ed93c/home-page-step-4.png",
        link: {
            linkLabel: 'Start Now',
            linkUrl: "https://app.airgram.io/signup"
        }
    }
];

export const userReviewData: IUserReviewCard[] = [
    {
        username: "Mattew C.",
        userJob: "Senior VP of Global Operations",
        userAvatar: "https://a.storyblok.com/f/167495/100x100/fa832d080a/profile-image-1.webp",
        reviewTitle: "The best meeting secretary in the market",
        reviewDesc: "Our meetings have never been the same since getting Airgram. It was outstanding how it could assist the meeting minute making process. We're still trying to learn the ropes on this software, so far we've been able to put it to good use. It is indeed a meeting tool all teams would benefit from.",
        reviewPlatformLogo: "https://a.storyblok.com/f/167495/x/c9866716f8/media-logo-g2.svg",
        platformDesc: "From G2"
    },
    {
        username: "Eylül N.",
        userJob: "Customer Success Specialist",
        userAvatar: "https://a.storyblok.com/f/167495/100x100/56a4255647/profile-image.webp",
        reviewTitle: "A good tool for customer meetings",
        reviewDesc: "We use Airgram primarily to record and transcribe meetings with our customers, and sometimes to record internal meetings(more strategic ones). I like the action items, it's easy to remember what you are supposed to do after a call. Also, I like that their plans offers flexibility for small teams.",
        reviewPlatformLogo: "https://a.storyblok.com/f/167495/x/c9866716f8/media-logo-g2.svg",
        platformDesc: "From G2"
    },
    {
        username: "Benjamin G.",
        userJob: "Client Success Director",
        userAvatar: "https://a.storyblok.com/f/167495/96x96/b366a5d25a/profile-image-3.jpeg",
        reviewTitle: "Great tool for recording and transcriptions",
        reviewDesc: "It’s easy to get Airgram to join meetings (Google Meet, Teams, Zoom). I love the fact that Airgram offers transcriptions that link to video time stamps, so you can easily go over a particular point. It also provides breakdowns showing who was talking at what times, for how long, great tool.",
        reviewPlatformLogo: "https://a.storyblok.com/f/167495/x/c9866716f8/media-logo-g2.svg",
        platformDesc: "From G2"
    },
]

export const featureCards: IFeatureIntro[] = [
    {
        iconUrl: "https://a.storyblok.com/f/167495/x/c3cf552450/ai-assistant.svg",
        featureName: "AI Assistant",
        featureDesc: "Schedule your Airgram assistant to join and record Zoom, Google Meet, and Microsoft Teams calls without your presence.",
    },
    {
        iconUrl: "https://a.storyblok.com/f/167495/x/0959c241a4/agenda-timer.svg",
        featureName: "Agenda Timer",
        featureDesc: "By creating an agenda with set duration, Airgram will track the agenda with a countdown timer to keep track of the meeting.",
    },
    {
        iconUrl: "https://a.storyblok.com/f/167495/x/8d8dcf89c6/chrome-extension.svg",
        featureName: "Chrome Extension",
        featureDesc: "Create meeting agenda, capture calls, and take meeting notes directly from the browser for Google Meet users.",
        link: {
            linkLabel: "👉 View Details",
            linkColor: "#854EFB",
            linkUrl: "https://www.airgram.io/product/google-meet"
        }
    },
    {
        iconUrl: "https://a.storyblok.com/f/167495/x/e4042f340e/audio-video-file-transcription.svg",
        featureName: "Audio/Video File Transcription",
        featureDesc: "Transcribe the uploaded audio and video files to text automatically. You can edit, save, and share the transcription afterward.",
    },
]

export const statisticalData: IStatisticalDataIntro[] = [
    {
        number: 98.3,
        suffix: '%',
        decimalPlaces: 1,
        desc: "Transcription accuracy rate"
    },
    {
        number: 1,
        suffix: " Day",
        decimalPlaces: 0,
        desc: "Is saved every week",
    },
    {
        number: 10000,
        suffix: "+",
        decimalPlaces: 0,
        desc: "Meetings transcribed",
    },
    {
        number: 2000,
        suffix: "+",
        decimalPlaces: 0,
        desc: "Organizations trust us"
    }
]

export const userReviewJobs = [
    "Team Leaders",
    "Customer Support",
    "Sales Teams"
]


export const featureVideoCards: IFeatureVideoIntro[] = [
    {
        featureWord: "Capture",
        featureWordBgColor: "#ECF3FF",
        featureWordFontColor: "#106DFF",
        featureTitle: "Save meeting recordings and transcripts for future access",
        featureDesc: [
            "Record video conferences in high-quality audio and video. Widely compatible with Zoom, Google Meet, and Microsoft Teams.",
            "Transcribe both online and offline meetings automatically with time stamps that make getting back to any information much faster.",
            "Eight transcription languages available: English, German, French, Spanish, Portuguese, Russian, Japanese, and Chinese."
        ],
        videoUrl: "https://d2rresadhaeqs7.cloudfront.net/HomeGif1.mp4",
        // link: {
        //     linkColor: "#9f2aec",
        //     linkLabel: "👉 Get Airgram Chrome Extension for Google Meet",
        //     linkUrl: "https://chrome.google.com/webstore/detail/google-meet-transcription/gfcfcfbheicekekhagikabglhfgnjdek"
        // }
    },
    {
        featureWord: "Collaborate",
        featureWordBgColor: "#FFF3E0",
        featureWordFontColor: "#FF932F",
        featureTitle: "Facilitate teams’ collaboration to drive action",
        featureDesc: [
            "Team members collaborate in real time to take notes and share feedback - anyone can contribute to the meeting to make the most of it.",
            "The integrated rich-text editor allows you to add text, agenda, bullet list, images, and more with a simple slash (/) command.",
            "Create action items with due dates so that you can end meetings with clear future plans."
        ],
        videoUrl: "https://d2rresadhaeqs7.cloudfront.net/HomeGif2.mp4",
        // link: {
        //     linkColor: "#9f2aec",
        //     linkLabel: "👉 Get Started",
        //     linkUrl: "https://app.airgram.io/signup"
        // }
    },
    {
        featureWord: "Search",
        featureWordBgColor: "#FFEDFB",
        featureWordFontColor: "#E73CC0",
        featureTitle: "Quickly review the recorded meetings",
        featureDesc: [
            "Search across the meeting transcript for a keyword or phrase fast; your valuable time is saved.",
            "Easily jump to part of the recording or watch the video at an adjustable speed. Review an hour-long call within 10 minutes.",
            "The advanced AI topic feature extracts crucial information like price, person, location and time to give you a quick meeting takeaway."
        ],
        videoUrl: "https://d2rresadhaeqs7.cloudfront.net/HomeGif3.mp4",
    },
    {
        featureWord: "Share",
        featureWordBgColor: "#EEECFF",
        featureWordFontColor: "#6956F2",
        featureTitle: "Share the meeting minutes to keep your team aligned",
        featureDesc: [
            "Clip and share a snippet of the recording with others to align on crucial information.",
            "Export notes and transcripts to Notion, Google Docs, Word, or Slack. Efficiently incorporate meeting highlights into your own knowledge.",
            "There is also the option to download the meeting recording to local storage so you can watch it offline anytime."
        ],
        videoUrl: "https://d2rresadhaeqs7.cloudfront.net/HomeGif4.mp4",
    },
]

export const honorIcons: IHonorIconIntro[] = [
    {
        alt: "SOC 2 certification",
        url: "https://a.storyblok.com/f/167495/x/92d627488c/aicpa-soc-logo.svg",
    },
    {
        alt: "G2 logo",
        url: "https://a.storyblok.com/f/167495/x/2cf78e6c8e/g2-logo.svg",
    },
    {
        alt: "TechCrunch logo",
        url: "https://a.storyblok.com/f/167495/x/d3b86e7277/tc-logo.svg",
    },
    {
        alt: "Product Hunt logo",
        url: "https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=352184&theme=light&period=daily",
        linkUrl: "https://www.producthunt.com/posts/airgram-2-0-for-zoom-google-meet-teams?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-airgram-2-0-for-zoom-google-meet-teams"
    },
]