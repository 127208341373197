import CommonButton from "@/components/common-button";
import LinkButton from "@/components/link-button";
import { LOADING_IMAGE_URL, WEB_APP_SIGN_UP_LINK } from "@/utils/constant";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import lozad from "lozad";
import { BannerLogo, HPAndG2Logo } from "@/content/home-page/constants";
import * as styles from "./index.module.less";

const HeroBanner = () => {
  useEffect(() => {
    const { observe } = lozad(".lozad", {
      loaded: (el) => {
        try {
          el.onload = () => {
            el.classList.add("image-blur");
          };
        } catch (err) {}
      },
    });

    observe();
  }, []);
  return (
    <Center
      p={{
        base: "48px 24px 64px",
        sm: "64px 40px",
        lg: "100px 40px 64px",
        xl: "100px 120px 64px",
        "2xl": "100px 160px 80px",
      }}
    >
      <Flex
        w="full"
        maxW="web_max_w"
        gap={{
          base: "24px",
          "2xl": "80px",
        }}
        flexDir={{
          base: "column",
          xl: "row",
        }}
      >
        <Box
          display="flex"
          flexDir="column"
          alignItems={{ base: "center", xl: "flex-start" }}
          justifyContent={{
            base: "center",
          }}
          flex={1}
          gap="24px"
        >
          <Text
            as="h1"
            maxW="700px"
            fontSize={{
              base: "32px !important",
              sm: "40px !important",
              lg: "44px !important",
            }}
            lineHeight={{
              base: "44px",
              sm: "54px",
              lg: "60px",
            }}
            textAlign={{ base: "center", xl: "left" }}
          >
            Focus on Your Meetings, Not on Note-taking
          </Text>
          <Text
            textAlign={{ base: "center", xl: "left" }}
            as="p"
            style={{ margin: "0px" }}
          >
            Airgram helps record, transcribe, summarize, and share meeting
            conversations. Maximize the value of both your internal and
            client-facing meetings.
          </Text>
          <Flex
            mt={{
              base: "0px",
              xl: "16px",
            }}
            flexDir="column"
            gap={{
              base: "24px",
            }}
          >
            <Flex
              flexDir="column"
              gap="20px"
              alignItems={{
                base: "center",
                xl: "flex-start",
              }}
            >
              <CommonButton
                gtmId="web_sign_up_cta_btn_homepage_hero"
                bg="linear-gradient(90deg, #E146F1 0%, #9047F1 100%)"
                content="Sign up for free"
                to={WEB_APP_SIGN_UP_LINK}
                boxShadow="0px 5.7851px 28.9255px rgba(231, 164, 255, 0.77)"
                w={{
                  base: "215px",
                  sm: "250px",
                }}
              />
              <Flex
                alignItems="center"
                gap={{
                  base: "14px",
                  sm: "16px",
                  lg: "20px",
                }}
              >
                <Text>Works with</Text>
                {BannerLogo.map((item, index) => {
                  return (
                    <Image
                      key={index}
                      w={{
                        base: "32px",
                        sm: "38px",
                      }}
                      h={{
                        base: "32px",
                        sm: "38px",
                      }}
                      objectFit="contain"
                      src={item.src}
                      alt={item.alt}
                    />
                  );
                })}
                <Box flexShrink="0" pos="relative">
                  <Image
                    src="https://a.storyblok.com/f/167495/38x39/99d4c2e5c1/webex-logo.svg"
                    alt="Webex icon"
                    w={{
                      base: "32px",
                      sm: "38px",
                    }}
                    h={{
                      base: "32px",
                      sm: "38px",
                    }}
                  />
                  <Image
                    alt="newly released icon"
                    src="https://a.storyblok.com/f/167495/45x21/b670113b34/new-1.svg"
                    pos="absolute"
                    left="0px"
                    top="0px"
                    w="45px"
                    h="20px"
                    objectFit="contain"
                    transform="translate(70%, -70%)"
                    className={styles.new_tooltip}
                  />
                </Box>
              </Flex>
            </Flex>
            <Flex
              gap={{
                base: "14px",
                sm: "16px",
                lg: "20px",
              }}
              alignItems="center"
              justifyContent={{
                base: "center",
                xl: "flex-start",
              }}
            >
              <Image
                maxH={{
                  base: "60px",
                  sm: "70px",
                  md: "80px",
                }}
                w="auto"
                objectFit="contain"
                src={HPAndG2Logo[0].src}
                alt={HPAndG2Logo[0].alt}
              />
              <Image
                maxH={{
                  base: "36px",
                  sm: "42px",
                  md: "52px",
                }}
                w="auto"
                objectFit="contain"
                src={HPAndG2Logo[1].src}
                alt={HPAndG2Logo[1].alt}
              />
            </Flex>
          </Flex>
        </Box>
        <Center flex={1}>
          <Image
            className="lozad"
            data-src="https://a.storyblok.com/f/167495/1520x1140/ad686b1f8d/heropage-banner.png"
            src={LOADING_IMAGE_URL}
            alt="Airgram hero page banner"
            objectFit="contain"
            w={{
              base: "full",
              lg: "688px",
              xl: "620px",
              "2xl": "760px",
            }}
            h={{
              base: "auto",
              lg: "516px",
              xl: "465px",
              "2xl": "570px",
            }}
            maxH={{
              base: "516px",
              lg: "570px",
            }}
          />
        </Center>
      </Flex>
    </Center>
  );
};

export default HeroBanner;
