import React from "react";
import * as styles from "./index.module.less";
import BlokContainer from "@/components/blok-container";
import customStyles from "@/assets/theme/customStyles";
import { Box, Flex, Text } from "@chakra-ui/react";
import CommonButton from "@/components/common-button";
import { WEB_APP_SIGN_UP_LINK } from "@/utils/constant";
import RatioMedia from "@/components/ratio-media";
import { ReactComponent as PublicStar } from "@/assets/images/public-star.svg";

const AISummary = () => {
  return (
    <BlokContainer maxW={customStyles.web_max_w}>
      <Flex
        className={styles.container}
        flexDir={{
          base: "column",
          lg: "row",
        }}
        borderRadius={{
          base: "24px",
          sm: "32px",
          lg: "48px",
        }}
        w={{
          base: "auto",
          lg: "full",
        }}
        justifyContent="space-between"
        p={{
          base: "48px 24px 40px",
          sm: "56px 24px 40px",
          md: "64px 48px 40px",
          lg: "56px 48px 40px",
          "2xl": "64px 64px 40px",
        }}
        gap={{
          base: "32px",
          sm: "40px",
          lg: "24px",
          xl: "40px",
        }}
        alignItems="center"
      >
        <Box className={styles.new_badge}>
          <Text
            as="span"
            fontWeight="700"
            fontSize={{
              base: "14px",
              sm: "18px",
            }}
            color="#fff"
          >
            New!
          </Text>
        </Box>
        <Flex
          flexDir="column"
          alignItems={{
            base: "center",
            lg: "flex-start",
          }}
          textAlign={{
            base: "center",
            lg: "left",
          }}
          maxW="560px"
          gap={{
            base: "24px",
            lg: "32px",
          }}
        >
          <Text as="h2">Summarize faster with Airgram AI</Text>
          <Text as="p">
            With one simple click, Airgram will generate a comprehensive meeting
            summary that extracts key insights and action items, powered by
            GPT-4. Summarize faster, work smarter, and free up your time to
            focus on more important work.
          </Text>
          <CommonButton
            boxShadow="0px 5.785100936889648px 28.925504684448242px 0px rgba(231, 164, 255, 0.77)"
            content="Try Now"
            to={WEB_APP_SIGN_UP_LINK}
            w={{
              base: "160px",
              lg: "200px",
            }}
            bg="linear-gradient(131deg, #FF36EB 0%, #9448F2 100%)"
            className={styles.shine_btn}
          >
            <PublicStar className={styles.star_icon} />
          </CommonButton>
        </Flex>
        <Box
          w="full"
          maxW="480px"
          flexShrink="0"
          flexBasis={{
            lg: "300px",
            xl: "380px",
            "2xl": "400px",
          }}
        >
          <RatioMedia
            media={{
              type: "image",
              src: "https://a.storyblok.com/f/167495/400x300/9056ed1b4c/home-page-ai-summary.svg",
              alt: "Airgram AI meeting summary",
              objectFit: "contain",
            }}
            mediaStyle={{
              borderRadius: "12px",
            }}
          />
        </Box>
      </Flex>
    </BlokContainer>
  );
};
export default AISummary;
