import BlokContainer from "@/components/blok-container";
import { FeaturesDetailsList } from "@/content/home-page/constants";
import { Box, Center, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FeatureContent from "./feature-content";
import * as styles from "./index.module.less";
import lozad from "lozad";

const FeaturesScroll = () => {
  const BLOCK_ID = "home-page-features-scroll";
  const [activeIndex, setActiveIndex] = useState(0);

  // 重新播放
  const handleReplayVideo = (index) => {
    const activeVideo = document.getElementById(
      `home-page-feature-video-${index}`
    );
    if (activeVideo instanceof HTMLVideoElement) {
      activeVideo.currentTime = 0;
    }
  };

  useEffect(() => {
    const options = {
      rootMargin: "0px",
      threshold: 0.7,
    };
    const observer = new IntersectionObserver((entires) => {
      let nextIndex = activeIndex;
      let maxRatio = Number.MIN_SAFE_INTEGER;
      entires.forEach((entry) => {
        if (entry.intersectionRatio <= 0) return;
        // 找出在屏幕中能看到最多的块
        if (
          entry.target instanceof HTMLElement &&
          entry.intersectionRatio >= maxRatio
        ) {
          maxRatio = entry.intersectionRatio;
          nextIndex = Number(entry.target.dataset.index);
        }
      });
      setActiveIndex(Number(nextIndex));
      handleReplayVideo(nextIndex);
    }, options);
    document.querySelectorAll(".content-item").forEach((el) => {
      observer.observe(el);
    });
    return () => {
      document.querySelectorAll(".content-item").forEach((el) => {
        observer.unobserve(el);
      });
    };
  }, []);

  useEffect(() => {
    const { observe } = lozad(".lozad", {
      loaded: (el) => {
        try {
          el.onload = () => {
            el.classList.add("image-blur");
          };
        } catch (err) {}
      },
    });

    observe();
  }, []);

  return (
    <BlokContainer
      other={{
        minHeight: "100vh",
        position: "relative",
        transition: "all 0.5s ease-in-out",
      }}
      blockBg={FeaturesDetailsList[activeIndex].bgColor}
      maxW="web_max_w"
    >
      <Flex w="full" pos="relative">
        {/* content */}
        <Flex flex="0 0 40%" flexDir="column" id={BLOCK_ID}>
          {FeaturesDetailsList.map((item, index) => {
            return (
              <Center key={index} minH="100vh">
                <FeatureContent
                  opacity={activeIndex === index ? 1 : 0.3}
                  dataIndex={index}
                  className="content-item"
                  {...item}
                />
              </Center>
            );
          })}
        </Flex>
        {/* video */}
        <Box
          className={styles.scroll_video_container}
          maxW="60%"
          w="100%"
          h="100vh"
          pos="sticky"
          top="0px"
          right="0px"
        >
          {FeaturesDetailsList.map((item, index) => {
            return (
              <Center
                flexDir="column"
                w="full"
                key={index}
                pos="absolute"
                top="0px"
                left="0px"
                h="100vh"
                pl={{
                  lg: "64px",
                  xl: "120px",
                  "2xl": "160px",
                }}
                opacity={index === activeIndex ? 1 : 0}
                transition="all 0.5s ease-in-out"
              >
                <video
                  id={`home-page-feature-video-${index}`}
                  className="lozad"
                  style={{
                    borderRadius: "12px",
                    objectFit: "contain",
                    width: "100%",
                    maxWidth: "780px",
                    height: "auto",
                    backgroundColor: `${item.bgColor}`,
                  }}
                  autoPlay
                  muted
                  loop
                >
                  <source data-src={item.videoLink} type="video/mp4" />
                </video>
              </Center>
            );
          })}
        </Box>
      </Flex>
    </BlokContainer>
  );
};

export default FeaturesScroll;
