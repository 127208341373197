import BlokContainer from "@/components/blok-container";
import { GatsbyLink } from "@/components/commons";
import RatioMedia from "@/components/ratio-media";
import { AccomplishMoreList } from "@/content/home-page/constants";
import { IAccomplishMore } from "@/content/home-page/type";
import { LOADING_IMAGE_URL } from "@/utils/constant";
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";

const FeatureCard = ({ image, title, desc }: IAccomplishMore) => {
  return (
    <Flex
      maxW="560px"
      flexDir="column"
      alignItems="center"
      p={{
        base: "16px 24px",
        sm: "24px 32px",
      }}
      borderRadius="24px"
      bg="#fff"
      gap={{
        base: "16px",
        lg: "20px",
        xl: "24px",
      }}
      boxShadow="0px 4px 40px 0px rgba(0, 0, 0, 0.05)"
    >
      <Box
        w="full"
        maxW={{
          base: "100px",
          sm: "120px",
          xl: "160px",
        }}
      >
        <RatioMedia
          media={{
            type: "image",
            src: image.src,
            alt: image.alt,
            objectFit: "contain",
          }}
          isLazyLoad
        />
      </Box>
      <Text as="h4" textAlign="center">{title}</Text>
      {desc}
    </Flex>
  );
};

const AccomplishMore = () => {
  return (
    <BlokContainer
      blockTitle={
        <Text as="h2" textAlign="center">
          Airgram Is Able to Accomplish More
        </Text>
      }
      maxW="web_max_w"
    >
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1fr 1fr",
        }}
        gap={{
          base: "32px",
          xl: "40px",
        }}
      >
        {AccomplishMoreList.map((item) => {
          return <FeatureCard key={item.title} {...item} />;
        })}
      </Grid>
    </BlokContainer>
  );
};

export default AccomplishMore;
