import { TeamsLogoList } from "@/content/home-page/constants";
import {
  Box,
  Center,
  Image,
  Flex,
  Text,
  Grid,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";

const TeamsLogo = () => {
  return (
    <Center
      p={{
        base: "0 24px 0",
        sm: "0 40px 0",
        lg: "0 40px 0",
        xl: "0 120px 0",
        "2xl": "0 160px 0",
      }}
    >
      <Center
        maxW="web_max_w"
        w="full"
        flexDir="column"
        gap={{
          base: "12px",
          sm: "24px",
          lg: "40px",
        }}
      >
        <Center w="full">
          <Box flex={1} h="1px" bg="rgba(193, 188, 202, 0.5)" />
          <Text
            px={{
              base: "12px",
              sm: "24px",
              lg: "40px",
            }}
            color="#797186"
            w="auto"
            textAlign="center"
            as="h5"
          >
            Trusted by 2,000+ ambitious teams
          </Text>
          <Box flex={1} h="1px" bg="rgba(193, 188, 202, 0.5)" />
        </Center>
        <Grid
          w="full"
          px={{
            base: "24px",
            sm: "12px",
            md: "24px",
            lg: "48px",
          }}
          alignSelf="center"
          justifyItems="center"
          gridColumnGap={{
            base: "24px",
            md: "32px",
            lg: "40px",
            xl: "80px",
          }}
          gridRowGap="12px"
          gridTemplateColumns={{
            base: "1fr 1fr 1fr",
            sm: "1fr 1fr 1fr 1fr 1fr 1fr",
          }}
        >
          {TeamsLogoList.map((item, index) => {
            return (
              <GridItem key={index}>
                <Image
                  maxH="40px"
                  objectFit="contain"
                  w="auto"
                  h="full"
                  src={item.src}
                  alt={item.alt}
                />
              </GridItem>
            );
          })}
        </Grid>
      </Center>
    </Center>
  );
};

export default TeamsLogo;
