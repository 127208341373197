import { Box, Center, Flex, Link, Text } from "@chakra-ui/react";
import { PublicArrowRight } from "react-notticon";
import React from "react";

interface IProp {
    label: string,
    linkUrl: string,
    isShowArrow?: boolean,
    gtmId?: string,
}

const LinkButton = ({ label, linkUrl, isShowArrow = true, gtmId }: IProp) => {
    return <Flex w="full">
        <Link
            data-gtm={gtmId}
            href={linkUrl}
            target="_blank"
            _hover={{
                textDecor: "none"
            }}
        >
            <Center color="#9F2AEC">
                <Text
                    as="button"
                    display="inline-block"
                    fontWeight="600"
                    fontSize={{
                        base: "14px",
                        sm: "16px",
                        md: "16px",
                        lg: "18px",
                        xl: "18px",
                        "2xl": "18px"
                    }}
                >
                    {label}
                </Text>
                {
                    isShowArrow &&
                    <Text
                        as={PublicArrowRight}
                        ml="0.25rem"
                        w={{
                            base: "16px",
                            sm: "18px",
                            lg: "20px",
                        }}
                        h={{
                            base: "16px",
                            sm: "18px",
                            lg: "20px",
                        }}
                    />
                }
            </Center>
        </Link>
    </Flex>
}

export default LinkButton