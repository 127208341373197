import CommonButton from "@/components/common-button";
import { CtaData } from "@/content/home-page/constants";
import { WEB_APP_SIGN_UP_LINK } from "@/utils/constant";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import React from "react";

const BottomCta = () => {
  return (
    <Center
      flexDir="column"
      w="full"
      px={{
        base: "24px",
        md: "40px",
        lg: "64px",
        xl: "120px",
        "2xl": "160px",
      }}
      py={{
        base: "40px",
        sm: "24px",
        md: "40px",
        lg: "40px",
        xl: "48px",
        "2xl": "64px",
      }}
      borderRadius={{
        base: "15px",
        sm: "20px",
        lg: "25px",
      }}
      bg="linear-gradient(108.21deg, #8654EA -8.41%, #EEE0FF 98.73%);"
      gap={{
        base: "32px",
        "2xl": "48px",
      }}
    >
      <Flex flexDir="column" gap="24px">
        <Text as="h2" textAlign="center" color="#ffffff">
          Make Every Meeting Worth Showing Up To
        </Text>
        <Center w="full">
          <CommonButton
            gtmId="web_sign_up_cta_btn_homepage_other"
            boxShadow="0px 18px 37px rgba(109, 57, 162, 0.19)"
            bg="#FFFFFF"
            color="#9F2AEC"
            content="Get Started for Free"
            to={WEB_APP_SIGN_UP_LINK}
          />
        </Center>
      </Flex>
      <Flex
        flexDir={{ base: "column", md: "row" }}
        gap={{
          base: "24px",
          sm: "36px",
          lg: "80px",
          xl: "100px",
          "2xl": "120px",
        }}
      >
        {CtaData.map((item, index) => {
          return (
            <Center key={index} flexDir="column">
              <Text as="h2" textAlign="center" color="#410786">
                {item.title}
              </Text>
              <Text as="h5" color="#410786" textAlign="center">
                {item.desc}
              </Text>
            </Center>
          );
        })}
      </Flex>
    </Center>
  );
};

export default BottomCta;
