import BlokContainer from "@/components/blok-container";
import UserReviewSwiper from "@/components/user-review-swiper";
import { userJobList, userReviewData } from "@/content/home-page/constants";
import React from "react";

const UserReview = () => {
  return (
    <BlokContainer
      blockTitle="Supercharge Meetings for Various Teams"
      titleContentGap="24px"
      maxW="web_max_w"
    >
      <UserReviewSwiper
        userReviewData={userReviewData}
        userJobList={userJobList}
      />
    </BlokContainer>
  );
};

export default UserReview;
