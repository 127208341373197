import BlokContainer from "@/components/blok-container";
import { PrivacyExperienceList } from "@/content/home-page/constants";
import { LOADING_IMAGE_URL } from "@/utils/constant";
import { Center, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import * as styles from "./index.module.less";

interface IListItem {
  title: string;
  desc: string;
  iconList?: { src: string; alt: string }[];
}

const ListItem = ({ title, desc, iconList }: IListItem) => {
  return (
    <Center
      gap={{
        base: "24px",
        lg: "64px",
        xl: "96px",
        "2xl": "120px",
      }}
      flexDir={{
        base: "column",
        md: "row",
      }}
    >
      <Flex flexDir="column" flex={1} gap="12px">
        <Text
          as="h4"
          textAlign={{
            base: "center",
            md: "left",
          }}
        >
          {title}
        </Text>
        <Text
          as="p"
          maxW="1200px"
          textAlign={{
            base: "center",
            md: "left",
          }}
        >
          {desc}
        </Text>
      </Flex>

      {iconList && (
        <Flex
          gap={{
            base: "16px",
            lg: "24px",
            xl: "40px",
          }}
        >
          {iconList.map((icon, index) => {
            return (
              <Image
                borderRadius="100%"
                className="lozad"
                src={LOADING_IMAGE_URL}
                w={{
                  base: "64px",
                  sm: "80px",
                  lg: "100px",
                  xl: "120px",
                }}
                h={{
                  base: "64px",
                  sm: "80px",
                  lg: "100px",
                  xl: "120px",
                }}
                data-src={icon.src}
                alt={icon.alt}
                key={index}
              />
            );
          })}
        </Flex>
      )}
    </Center>
  );
};

const PrivacySupport = () => {
  return (
    <BlokContainer
      blockBg="#F8F9FE"
      blockTitle="We Value Your Privacy and Experience"
      maxW="web_max_w"
    >
      <Center
        alignItems="flex-start"
        flexDir="column"
        gap={{
          base: "30px",
          lg: "40px",
          "2xl": "60px",
        }}
        className={styles.privacy_box}
      >
        {PrivacyExperienceList.map((item, index) => {
          return <ListItem key={index} {...item} />;
        })}
      </Center>
    </BlokContainer>
  );
};

export default PrivacySupport;
