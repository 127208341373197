import { IUserReviewCard, userReviewJobs } from "@/content/index/Constants";
import {
  Box,
  Center,
  Flex,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import LineProgress from "../line-progress";
import UserReviewCard from "../user-review-card";

interface IProp {
  userReviewData: IUserReviewCard[];
  userJobList?: string[];
}

const UserReviewSwiper = ({
  userReviewData,
  userJobList = userReviewJobs,
}: IProp) => {
  const during = 8000;
  const [activeIndex, setActiveIndex] = useState(1);
  const [direction, setDirection] = useState<"toLeft" | "toRight">("toRight");
  const [swiper, setSwiper] = useState(null);
  const cardStyle = [
    {
      userCardBg: "#FFF5EA",
      reviewCardBg: "#F2DEB7",
      fontColor: "#690C2E",
    },
    {
      userCardBg: "#ECF3FF",
      reviewCardBg: "#C0CBF2",
      fontColor: "#1A2C6A",
    },
    {
      userCardBg: "#EFFCEE",
      reviewCardBg: "#DBF8E8",
      fontColor: "#135739",
    },
  ];

  const handlePaginationClick = (index) => {
    setActiveIndex(index + 1);
    swiper.slideTo(index + 1);
  };

  const isBase = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    {
      fallback: "sm",
    }
  );

  return (
    <Center w="full" flexDir="column">
      <Center
        w="full"
        gap={{
          sm: "24px",
          md: "40px",
          lg: "48px",
        }}
      >
        {userJobList.map((item, index) => {
          return (
            (!isBase || activeIndex === index + 1) && (
              <Flex key={index} flexDir="column">
                <Box
                  fontWeight="600"
                  fontSize={{
                    base: "14px",
                    sm: "16px",
                    lg: "18px",
                  }}
                  p={{
                    base: "6px",
                    sm: "8px",
                    lg: "10px",
                    xl: "12px",
                  }}
                  onClick={() => handlePaginationClick(index)}
                  cursor="pointer"
                >
                  {item}
                </Box>
                {activeIndex === index + 1 && (
                  <LineProgress during={during / 1000} />
                )}
              </Flex>
            )
          );
        })}
      </Center>
      <Center
        w="full"
        mt={{
          base: "40px",
          sm: "64px",
          md: "64px",
          lg: "80px",
          xl: "80px",
          "2xl": "80px",
        }}
      >
        <Swiper
          className="home-page-user-review-swiper"
          loop
          spaceBetween={100}
          autoplay={{
            delay: during,
            disableOnInteraction: false,
          }}
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
          onSlideChange={(swiper) => {
            // 设置移动的方向
            if (swiper.activeIndex > activeIndex) {
              setDirection("toLeft");
            } else {
              setDirection("toRight");
            }
            // swiper.activeIndex会多一个index
            if (swiper.activeIndex > userReviewData.length) {
              setActiveIndex(1);
            }
            // 从开头往回滑动activeIndex会变0
            else if (swiper.activeIndex === 0) {
              setActiveIndex(userReviewData.length);
            } else {
              setActiveIndex(swiper.activeIndex);
            }
          }}
          pagination={
            isBase
              ? {
                  type: "bullets",
                  clickable: true,
                }
              : false
          }
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Navigation, Pagination, Autoplay]}
        >
          {userReviewData.map((item, index) => {
            return (
              <SwiperSlide
                style={{
                  background: "transparent",
                  width: "1000px",
                  position: "relative",
                }}
                key={item.username}
              >
                <UserReviewCard
                  isActive={activeIndex === index + 1}
                  direction={direction}
                  data={item}
                  key={item.username}
                  userCardBg={cardStyle[index].userCardBg}
                  reviewCardBg={cardStyle[index].reviewCardBg}
                  fontColor={cardStyle[index].fontColor}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Center>
      <Center
        w="full"
        mt={{
          base: "24px",
          sm: "40px",
          md: "64px",
          lg: "64px",
          xl: "80px",
          "2xl": "80px",
        }}
      >
        <Link
          href="https://www.g2.com/products/airgram/reviews"
          target="_blank"
          _hover={{
            textDecor: "none",
          }}
          id="home_g2_link"
        >
          <Text as="h5" color="#9F2AEC">
            👉 More Reviews On G2
          </Text>
        </Link>
      </Center>
    </Center>
  );
};

export default UserReviewSwiper;
