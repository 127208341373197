import { IUserReviewCard } from "@/content/index/Constants";
import {
  Box,
  Center,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";

interface IProp {
  data: IUserReviewCard;
  userCardBg?: string;
  reviewCardBg?: string;
  fontColor?: string;
  isActive?: boolean;
  direction?: "toLeft" | "toRight";
}

const UserReviewCard = ({
  data,
  userCardBg = "#FFF5EA",
  reviewCardBg = "#F2DEB7",
  fontColor = "#690C2E",
  isActive,
  direction = "toLeft",
}: IProp) => {
  const isColumn = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    {
      fallback: "base",
    }
  );

  const isFillBgColor = useBreakpointValue({
    base: true,
    md: false,
  });

  const calUserCardClassName = useMemo(() => {
    if (isActive && direction === "toRight") {
      return "user-card-to-right";
    } else {
      return "";
    }
  }, [isActive, direction]);

  const calReviewCardClassName = useMemo(() => {
    if (isActive && direction === "toLeft") {
      return "review-card-to-left";
    } else {
      return "";
    }
  }, [isActive, direction]);

  return (
    <Flex
      borderRadius="24px"
      w={{
        lg: "876px",
        xl: "1076px",
      }}
      margin="auto"
      flexDir={{
        base: "column",
        lg: "row",
      }}
      alignItems={isColumn ? "center" : "inherit"}
      pos="relative"
      color={fontColor}
      bg={isFillBgColor ? userCardBg : "transparent"}
      cursor="grab"
      _active={{
        cursor: "grabbing",
      }}
    >
      {/* userCard */}
      <Center
        className={calUserCardClassName}
        w={{
          base: "full",
          md: "560px",
          lg: "676px",
        }}
        p={{
          base: "24px",
          sm: "40px",
          lg: "64px 40px",
          xl: "80px",
        }}
        borderRadius="24px"
        flexDir={{
          base: "column",
          sm: "row",
          lg: "column",
        }}
        bg={userCardBg}
        alignItems="flex-start"
        gap={{
          base: "24px",
          sm: "40px",
        }}
        borderBottomRadius={isColumn ? "0px" : "24px"}
      >
        {/* 头像 */}
        <Box pos="relative" flexShrink="0">
          <Box
            p="4px"
            borderRadius="100%"
            pos="absolute"
            zIndex={1}
            bottom="-10%"
            right="-10%"
          >
            <Image
              w={{
                base: "40px",
                sm: "48px",
                lg: "56px",
                xl: "72px",
              }}
              h={{
                base: "40px",
                sm: "48px",
                lg: "56px",
                xl: "72px",
              }}
              objectFit="fill"
              src={data.reviewPlatformLogo}
              className="lozad"
            />
          </Box>
          <Image
            w={{
              base: "80px",
              sm: "96px",
              lg: "120px",
              xl: "160px",
            }}
            h={{
              base: "80px",
              sm: "96px",
              lg: "120px",
              xl: "160px",
            }}
            objectFit="fill"
            loading="lazy"
            className="lozad"
            data-src={data.userAvatar}
            borderRadius="100%"
          />
        </Box>
        {/* 介绍 */}
        <Flex
          w="full"
          flexDir="column"
          rowGap={{
            base: "4px",
            sm: "6px",
            md: "6px",
            lg: "8px",
            xl: "8px",
            "2xl": "8px",
          }}
          textAlign="left"
        >
          <Box
            w="full"
            fontWeight="700"
            fontSize={{
              base: "22px",
              sm: "24px",
              md: "24px",
              lg: "26px",
              xl: "28px",
            }}
          >
            {data.username}
          </Box>
          <Box
            w="full"
            fontWeight="600"
            fontSize={{
              base: "12px",
              sm: "16px",
              lg: "18px",
            }}
          >
            {data.userJob}
          </Box>
          {/* <Box
            w="full"
            fontWeight="600"
            fontSize={{
              base: "12px",
              sm: "16px",
              lg: "18px",
            }}
          >
            {data.platformDesc}
          </Box> */}
        </Flex>
      </Center>
      {/* reviewCard */}
      {isColumn ? (
        <Center
          p={{
            base: "24px",
            sm: "40px",
          }}
          bg={reviewCardBg}
          borderRadius="24px"
          flexDir="column"
          boxShadow={{
            base: "0px 24px 24px rgba(75, 70, 16, 0.12)",
            sm: "0px 24px 48px rgba(75, 70, 16, 0.12)",
          }}
          textAlign="left"
          w="full"
          className="home-page-reviewCard"
          gap={{
            base: "8px",
            sm: "12px",
            lg: "16px",
            xl: "24px",
          }}
        >
          <Box
            fontStyle="italic"
            fontWeight="700"
            fontSize={{
              base: "22px",
              sm: "24px",
              lg: "26px",
              xl: "28px",
            }}
          >
            "{data.reviewTitle}"
          </Box>
          <Text>{data.reviewDesc}</Text>
        </Center>
      ) : (
        <Center
          className={calReviewCardClassName}
          pos="absolute"
          p="40px"
          bg={reviewCardBg}
          borderRadius="24px"
          flexDir="column"
          boxShadow={{
            lg: "-24px 24px 48px rgba(75, 70, 16, 0.12)",
            xl: "-24px 11px 48px rgba(75, 70, 16, 0.12)",
          }}
          textAlign="left"
          w={{
            lg: "520px",
            xl: "640px",
          }}
          top="50%"
          right="0px"
          transform="translate(0%, -50%)"
          gap={{
            base: "8px",
            sm: "12px",
            lg: "16px",
            xl: "24px",
          }}
        >
          <Box
            w="full"
            fontStyle="italic"
            fontWeight="700"
            fontSize={{
              base: "22px",
              sm: "24px",
              lg: "26px",
              xl: "28px",
            }}
          >
            "{data.reviewTitle}"
          </Box>
          <Text>{data.reviewDesc}</Text>
        </Center>
      )}
    </Flex>
  );
};

export default UserReviewCard;
