import { WEB_APP_SIGN_UP_LINK } from "@/utils/constant";
import { IImage } from "@/utils/type";
import { IUserReviewCard } from "../index/Constants";
import { IAccomplishMore, IFeatureVideo, IPrivacyExperience } from "./type";
import { Link, Text } from "@chakra-ui/react";
import React from "react";

// banner logo
export const BannerLogo: IImage[] = [
  {
    src: "https://a.storyblok.com/f/167495/x/f826b7f139/microsoft-teams.svg",
    alt: "MS Teams icon",
  },
  {
    src: "https://a.storyblok.com/f/167495/x/0d9e22fdb0/google-meet.svg",
    alt: "Google Meet icon",
  },
  {
    src: "https://a.storyblok.com/f/167495/x/129565c927/zoom.svg",
    alt: "Zoom icon",
  },
];

export const HPAndG2Logo: IImage[] = [
  {
    src: "https://a.storyblok.com/f/167495/x/7285b203e8/highperformer.svg",
    alt: "G2 high performer badge",
  },
  {
    src: "https://a.storyblok.com/f/167495/157x53/3db285117b/ph.svg",
    alt: "G2 high performer badge",
  },
];

// ambitious teams logo
export const TeamsLogoList: IImage[] = [
  {
    src: "https://a.storyblok.com/f/167495/x/bf3963232a/logo_grammarly.svg",
    alt: "Grammarly company logo",
  },
  {
    src: "https://a.storyblok.com/f/167495/x/17bf51210a/logo_survicate.svg",
    alt: "Survicate company logo",
  },
  {
    src: "https://a.storyblok.com/f/167495/x/0c78da100e/logo_cps.svg",
    alt: "Chicago Public School logo",
  },
  {
    src: "https://a.storyblok.com/f/167495/x/3be5bf5237/logo_velvet.svg",
    alt: "Velvet company logo",
  },
  {
    src: "https://a.storyblok.com/f/167495/x/62333b1759/logo_grab.svg",
    alt: "Grab company logo",
  },
  {
    src: "https://a.storyblok.com/f/167495/x/12d4fe68e2/logo_clickguard.svg",
    alt: "Click guard company logo",
  },
];

// problems need airgram
export const ProblemsList = [
  {
    emoji: {
      src: "https://a.storyblok.com/f/167495/80x81/f5c7bef399/emoji-1.png",
      alt: "emoji 1",
    },
    problem: "Struggle with taking meeting notes",
  },
  {
    emoji: {
      src: "https://a.storyblok.com/f/167495/81x81/4a5743d4f2/emoji-2.png",
      alt: "emoji 2",
    },
    problem: "Meetings are unorganized",
  },
  {
    emoji: {
      src: "https://a.storyblok.com/f/167495/81x81/18ae774d3c/emoji-3.png",
      alt: "emoji 3",
    },
    problem: "Find it hard to follow up a meeting",
  },
  {
    emoji: {
      src: "https://a.storyblok.com/f/167495/80x81/9ba76f9996/emoji-4.png",
      alt: "emoji 4",
    },
    problem: "Miss important information in a call",
  },
  {
    emoji: {
      src: "https://a.storyblok.com/f/167495/81x81/90fb94c3d4/emoji-5.png",
      alt: "emoji 5",
    },
    problem: "Lose track of meeting decisions",
  },
  {
    emoji: {
      src: "https://a.storyblok.com/f/167495/81x81/358d056d0b/emoji-6.png",
      alt: "emoji 6",
    },
    problem: "Meeting notes are scattered",
  },
];

// integrate app
export const IntegrateAppDescList = [
  "Calendar:  Google Calendar and Outlook Calendar",
  "Conferencing: Zoom, Google Meet, Microsoft Teams",
  "Documentation: Notion, MS Word, Google Docs",
  "Communication: Slack",
  "Automation: Zapier",
];

// accomplish more
export const AccomplishMoreList: IAccomplishMore[] = [
  {
    title: "Meeting schedule",
    desc: (
      <Text as="p">
        Sync your calendar availability and create a shareable booking link to
        get a meeting scheduled easily; no more lengthy email exchanges.
      </Text>
    ),
    image: {
      src: "https://a.storyblok.com/f/167495/160x120/10a5485ebe/pic-2.svg",
      alt: "schedule a meeting",
    },
  },
  {
    title: "Auto-join meeting",
    desc: (
      <Text as="p">
        Set your Airgram assistant to automatically join and record online calls
        at the scheduled start time even without presence.
      </Text>
    ),
    image: {
      src: "https://a.storyblok.com/f/167495/160x120/9bf5df52af/pic-3.svg",
      alt: "auto-join meeting",
    },
  },
  {
    title: "Chrome Extension",
    desc: (
      <Text as="p">
        Record Google Meet calls directly from a browser using a{" "}
        <Link
          href="https://chrome.google.com/webstore/detail/airgram-transcribe-record/gfcfcfbheicekekhagikabglhfgnjdek"
          textDecor="underline"
          color="#1B1E28"
          target="_blank"
        >
          Chrome Extension
        </Link>{" "}
        in one click.
      </Text>
    ),
    image: {
      src: "https://a.storyblok.com/f/167495/160x120/85b4370d4e/pic.svg",
      alt: "chrome extension",
    },
  },
  {
    title: "Audio/video to text",
    desc: (
      <Text as="p">
        Transcribe meeting recordings, podcasts, interviews, and other
        video/audio files to text fast and accurately with AI.
      </Text>
    ),
    image: {
      src: "https://a.storyblok.com/f/167495/160x120/27a81c5845/pic-1.svg",
      alt: "transcribe audio to text",
    },
  },
];

// Privacy and experience
export const PrivacyExperienceList: IPrivacyExperience[] = [
  {
    title: "Enterprise-grade security",
    desc: "Keeping our clients' data secure is an absolute top priority at Airgram. We are SOC 2 and GDPR compliant and store all data on Amazon Web Services (AWS) to ensure your data is always safe. ",
    iconList: [
      {
        src: "https://a.storyblok.com/f/167495/x/b412a70a00/loog-aicpa-soc.svg",
        alt: "SOC 2 icon",
      },
      {
        src: "https://a.storyblok.com/f/167495/x/2abfb2f24b/loog-gpdr.svg",
        alt: "GDPR icon",
      },
    ],
  },
  {
    title: "Dedicated customer support",
    desc: "By offering live chat, email support, and free demos, the Airgram team strive to provide customers the best service. Whenever you need support, we are always there to help. ",
  },
];

// feature video details
export const FeaturesDetailsList: IFeatureVideo[] = [
  {
    tag: {
      bgColor: "#8159E5",
      label: "Meeting Recording",
    },
    title: "Keep a permanent record for future reference",
    desc: "Capture client meetings, customer feedback, coaching sessions, and other online calls in high-quality video and audio. Revisit the recording at any time and quickly scan through it with timestamps.",
    linkBtn: {
      label: "👉 Start Now",
      url: WEB_APP_SIGN_UP_LINK,
    },
    bgColor: "#FBF8FF",
    videoLink:
      "https://a.storyblok.com/f/167495/x/c80dc84bbc/meeting-recording.mp4",
  },
  {
    tag: {
      bgColor: "#F6CA45",
      label: "Live Transcription",
    },
    title: "Worry less about taking notes, engage more in conversations",
    desc: "Airgram automatically transcribes speech into text that is searchable, editable, and exportable to platforms like Notion and Slack. You can focus on the conversations instead of trying to scribble down notes. ",
    tips: "Supported languages: English, Spanish, German, French, Portuguese, Russian, Japanese, and Chinese. ",
    linkBtn: {
      label: "👉 Start Now",
      url: WEB_APP_SIGN_UP_LINK,
    },
    bgColor: "#FFFBF5",
    videoLink:
      "https://a.storyblok.com/f/167495/x/bd2241de4d/live-transcription.mp4",
  },
  {
    tag: {
      bgColor: "#6BBFFE",
      label: "Insights Sharing",
    },
    title: "Turn meeting highlights into sharable video clips",
    desc: "Instead of sending the whole story, you can create a video snippet to share only the most important part of your meeting in one click.",
    linkBtn: {
      label: "👉 Start Now",
      url: WEB_APP_SIGN_UP_LINK,
    },
    bgColor: "#F4F9FE",
    videoLink:
      "https://a.storyblok.com/f/167495/x/a7efa1c696/insights-sharing.mp4",
  },
  {
    tag: {
      bgColor: "#EC79AD",
      label: "Team Collaboration",
    },
    title: "Collaborate like you are in the same room",
    desc: "Without switching to other tools, all meeting attendees can simultaneously take notes, share a comment, and set to-dos before, during, and after the meeting. Team’s contribution help make the most out of each call.",
    linkBtn: {
      label: "👉 Start Now",
      url: WEB_APP_SIGN_UP_LINK,
    },
    bgColor: "#FFF8FC",
    videoLink:
      "https://a.storyblok.com/f/167495/x/b4a9273baf/team-collaboration.mp4",
  },
  {
    tag: {
      bgColor: "#52B588",
      label: "Meeting Agenda",
    },
    title: "Get everyone aligned and prepared in advance",
    desc: "Great meeting starts with a well-structured agenda. Simply develop a meeting agenda that covers all essential topics and share it in advance to keep everyone on the same page. ",
    linkBtn: {
      label: "👉 Start Now",
      url: WEB_APP_SIGN_UP_LINK,
    },
    bgColor: "#F3FBF5",
    videoLink:
      "https://a.storyblok.com/f/167495/x/4f803cfa2c/meeting-agenda.mp4",
  },
];

// user review
export const userReviewData: IUserReviewCard[] = [
  {
    username: "Eylül N.",
    userJob: "Customer Success Specialist",
    userAvatar:
      "https://a.storyblok.com/f/167495/512x512/ec48a68544/user-review-profile-2.webp",
    reviewTitle: "A good tool for customer meetings",
    reviewDesc:
      "We use Airgram primarily to record and transcribe meetings with our customers. I like the action items, it's easy to remember what you are supposed to do after a call. Also, I like that their plans offers flexibility for small teams.",
    reviewPlatformLogo:
      "https://a.storyblok.com/f/167495/x/c9866716f8/media-logo-g2.svg",
    platformDesc: "From G2",
  },
  {
    username: "Benjamin G.",
    userJob: "Client Success Director",
    userAvatar:
      "https://a.storyblok.com/f/167495/512x512/87e46506a7/user-review-profile-3.webp",
    reviewTitle: "Great tool for recording and transcriptions",
    reviewDesc:
      "Airgram is easy to set up and offers transcriptions that link to video time stamps, so you can easily go over a particular point. It is also very helpful to cut some clips to share it with others.",
    reviewPlatformLogo:
      "https://a.storyblok.com/f/167495/x/c9866716f8/media-logo-g2.svg",
    platformDesc: "From G2",
  },
  {
    username: "Austin Lee",
    userJob: "Marketing Specialist",
    userAvatar:
      "https://a.storyblok.com/f/167495/512x512/212a4f8e51/user-review-profile-1.webp",
    reviewTitle: "No more worry about meeting notes",
    reviewDesc:
      "Airgram just works. It connects and captures everything. You can take notes during the meeting or review afterwards and see what Airgram decided was important. The keyword feature is great as well.",
    reviewPlatformLogo:
      "https://a.storyblok.com/f/167495/x/c9866716f8/media-logo-g2.svg",
    platformDesc: "From G2",
  },
];

// user jobs
export const userJobList = ["Customer Success", "Sales", "Marketing"];

// bottom cta
export const CtaData = [
  {
    title: "1 Day",
    desc: "Is saved every week",
  },
  {
    title: "40,000+",
    desc: "Meetings transcribed",
  },
  {
    title: "2,000+",
    desc: "Organizations trust us",
  },
];
