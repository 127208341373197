import BlokContainer from "@/components/blok-container";
import { FeaturesDetailsList } from "@/content/home-page/constants";
import { Center, Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import FeatureContent from "./feature-content";
import lozad from "lozad";
import { LOADING_IMAGE_URL } from "@/utils/constant";

const FeaturesSwiper = () => {
  useEffect(() => {
    const { observe } = lozad(".lozad", {
      loaded: (el) => {
        el.classList.add("fade");
      },
    });

    observe();
  }, []);
  return (
    <BlokContainer>
      <Swiper
        loop
        autoplay={{
          delay: 10000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        }}
        spaceBetween={100}
        pagination={{
          clickable: true,
          type: "bullets",
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Navigation, Pagination, Autoplay]}
      >
        {FeaturesDetailsList.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Flex
                flexDir="column"
                gap={{
                  base: "24px",
                  sm: "40px",
                }}
              >
                <Center w="full">
                  <video
                    className="lozad"
                    style={{
                      borderRadius: "12px",
                      objectFit: "fill",
                      height: "100%",
                      maxHeight: "516px",
                      maxWidth: "688px",
                    }}
                    width="100%"
                    autoPlay
                    muted
                    loop
                  >
                    <source data-src={item.videoLink} type="video/mp4" />
                  </video>
                </Center>
                <FeatureContent {...item} />
              </Flex>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </BlokContainer>
  );
};

export default FeaturesSwiper;
