import { Box, Center } from "@chakra-ui/react";
import React from "react";

interface IProp {
    during: number,
    lineHeight?: string,
    lineColor?: string
}

const LineProgress = ({ during, lineHeight = "2px", lineColor = "#9F2AEC" }: IProp) => {
    return <Center w="full" h={lineHeight}>
        <Box
            w="full"
            h="full"
            animation={`line-progress-move ${during}s linear 1`}
            bg={lineColor}
        >
        </Box>
    </Center>
}

export default LineProgress;