import LinkButton from "@/components/link-button";
import { IFeatureVideo } from "@/content/home-page/type";
import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const FeatureContent = ({
  tag,
  title,
  desc,
  tips,
  linkBtn,
  videoLink,
  bgColor,
  className,
  dataIndex,
  opacity,
}: IFeatureVideo & {
  className?: string;
  dataIndex?: number;
  opacity?: number;
}) => {
  return (
    <Flex
      data-index={dataIndex}
      className={className}
      flexDir="column"
      height={{
        base: "auto",
        lg: "100vh",
      }}
      gap={{
        base: "32px",
        "2xl": "48px"
      }}
      transition="all 0.3s ease-in-out"
      opacity={opacity}
      justifyContent="center"
    >
      {/* tag & title */}
      <Flex flexDir="column" gap="24px" alignItems="flex-start">
        <Box
          borderRadius="10px"
          py={{ base: "10px" }}
          px={{ base: "24px" }}
          bg={tag.bgColor}
        >
          <Text w="auto" as="h4" color="#ffffff">
            {tag.label}
          </Text>
        </Box>
        <Text as="h2" textAlign="left">
          {title}
        </Text>
      </Flex>
      {/* desc */}
      <Flex flexDir="column" gap="24px">
        <Text
          as="p"
          style={{
            margin: "0px",
          }}
          textAlign="left"
          color="#7E7186"
        >
          {desc}
        </Text>
        {tips && (
          <Box
            textAlign="left"
            style={{ margin: "0" }}
            color="#7E7186"
            opacity="0.5"
            fontSize={{
              base: "12px",
              lg: "16px",
            }}
            lineHeight={{
              base: "22px",
              lg: "26px",
            }}
          >
            {tips}
          </Box>
        )}
      </Flex>
      {/* link */}
      <LinkButton gtmId="web_sign_up_cta_btn_homepage_other" label={linkBtn.label} linkUrl={linkBtn.url} />
    </Flex>
  );
};

export default FeatureContent;
