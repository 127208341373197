import BlokContainer from "@/components/blok-container";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import lozad from "lozad";
import { LOADING_IMAGE_URL } from "@/utils/constant";
import { IntegrateAppDescList } from "@/content/home-page/constants";
import RatioMedia from "@/components/ratio-media";

const IntegrateApp = () => {
  useEffect(() => {
    const { observe } = lozad(".lozad", {
      loaded: (el) => {
        try {
          el.onload = () => {
            el.classList.add("image-blur");
          };
        } catch (err) {}
      },
    });

    observe();
  }, []);
  return (
    <BlokContainer
      blockBg="#F8F9FF"
      blockTitle="Seamlessly Integrate with Your Favorite Apps"
      blockSubTitle={
        <Text
          style={{ margin: "0", marginTop: "24px" }}
          as="p"
          color="#7E7186"
          textAlign="center"
          maxW="1200px"
        >
          View scheduled meetings, share meeting notes, and collaborate with
          teammates - Airgram's seamless integration make all these much easier.
        </Text>
      }
      maxW="web_max_w"
    >
      <Center maxW="1000px" w="full">
        <RatioMedia
          media={{
            type: "image",
            src: "https://a.storyblok.com/f/167495/1500x811/6ffe971c99/integration.webp",
            alt: "Airgram integrations",
          }}
          ratio={0.54}
        />
      </Center>
    </BlokContainer>
  );
};

export default IntegrateApp;
